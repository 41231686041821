
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("app/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "TiltNeon";
  src: local("TiltNeon"), url("app/assets/fonts/TiltNeon-Regular.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

.text-truncate {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f2f2f2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e7e7e7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

